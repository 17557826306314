var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('h2',{staticClass:"margin-bottom-16"},[_vm._v("Gestion des prises de contact utilisateur")]),_c('v-dialog',{model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',[_c('v-textarea',{attrs:{"value":_vm.message,"label":"Message utilisateur","outlined":"","readonly":"","max-width":"500px"}})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){_vm.dialog = false}}},[_vm._v("Fermer")])],1)],1)],1),_c('v-card',[_c('v-card-text',{staticClass:"table-container"},[_c('v-row',{staticClass:"grid-filters"},[_c('v-col',{attrs:{"cols":"4"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":"","icon":"","fab":""},on:{"click":_vm.exportDonnees}},on),[_c('v-icon',{attrs:{"large":"","color":"dark-blue"}},[_vm._v(" cloud_download ")])],1)]}}])},[_c('span',[_vm._v("Exporter les contacts")])])],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.demandesUtilisateurs,"search":_vm.search,"show-expand":"","loading":_vm.loading,"options":_vm.pagination,"server-items-length":_vm.pagination.totalItems,"items-per-page-text":_vm.pagination.itemsPerPageText,"footer-props":{
                            pageText: '{0} - {1} sur {2}',
                            itemsPerPageOptions: _vm.pagination.itemsPerPageOptions,
                          },"no-data-text":_vm.$t('view.DataTable_Aucun_Resultat')},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"item",fn:function(props){return [_c('tr',[_c('td',[_vm._v(_vm._s(props.item.id))]),_c('td',[_vm._v(_vm._s(props.item.societe))]),_c('td',[_vm._v(_vm._s(props.item.siret))]),_c('td',[_vm._v(_vm._s(props.item.departement.libelle))]),_c('td',[_vm._v(_vm._s(props.item.compteWebCeeExistent ? 'Oui': 'Non'))]),_c('td',[_vm._v(_vm._s(props.item.nom))]),_c('td',[_vm._v(_vm._s(props.item.prenom))]),_c('td',[_vm._v(_vm._s(props.item.email))]),_c('td',[_vm._v(_vm._s(props.item.telephone))]),_c('td',[_vm._v(_vm._s(props.item.objetDemande.libelle))]),_c('td',[_vm._v(_vm._s(props.item.numeroDossier))]),_c('td',[_c('confirm',{ref:"confirm"}),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                          var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"small":"","color":"primary"},on:{"click":function($event){return _vm.afficherMessage(props.item.message)}}},on),[_vm._v("message")])]}}],null,true)},[_c('span',[_vm._v("Message demande")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                          var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.supprimerDemande(props.item)}}},on),[_vm._v("delete")])]}}],null,true)},[_c('span',[_vm._v("Supprimer demande")])])],1)])]}},{key:"no-data",fn:function(){return [_c('p',{staticClass:"no-data"},[_vm._v(_vm._s(_vm.loading ? 'Chargement des données en cours...' : 'Aucune donnée à afficher'))])]},proxy:true}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }