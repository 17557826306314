import { Component, Vue, Mixins } from 'vue-property-decorator';
import { GrilleMixin } from '@/shared/mixins/Grille.mixin';
import template from './GestionDemandesUtilisateurs.Template.vue';
import Confirm from '@/components/Confirm.vue';
import { ApiService } from '@/services/base/ApiService';
import { ApiHelper } from '@/services/ApiHelper';
import { DemandeUtilisateur } from '@/models';
import { CriteriaBase, PagingAndSorting, FileContentResult } from '@/shared/models';

@Component({
    ...template,
    components: {
        Confirm,
    },
    name: 'GestionDemandesUtilisateurs',
})
export default class GestionDemandesUtilisateurs extends Mixins(GrilleMixin) {
    private headers: any[];
    private dialog: boolean = false;
    private message: string | null = null;
    private loading: boolean = false;
    private search: string | null = null;

    public demandesUtilisateurs: DemandeUtilisateur[];
    public criteria: CriteriaBase = new CriteriaBase();
    public totalDemandesUtilisateurs: number = 0;

    // Définition de refs.
    public $refs!: Vue['$refs'] & {
        confirm: {
            open: ((
                title: string | null,
                message: string | null,
                options: { color?: string; width?: number; zIndex?: number }
            ) => Promise<boolean>);
        };
    };

    /**
     * Crée une instance de GestionDemandesUtilisateurs.
     * @memberof GestionDemandesUtilisateurs
     */
    constructor() {
        super();
        this.headers = [];
        this.demandesUtilisateurs = [];
    }

    /**
     * Événement executé apres la creation.
     *
     * @memberof GestionDemandesUtilisateurs
     */
    public created() {
        this.initialize();
        this.criteria.pagingAndSorting = new PagingAndSorting();
        this.getData();
    }

    /**
     * Initialisation des valeurs implicites.
     *
     * @memberof GestionDemandesUtilisateurs
     */
    public initialize() {
        this.headers = [
            { text: 'Référence', value: 'id' },
            { text: 'Société', value: 'societe' },
            { text: 'SIRET', value: 'siret' },
            { text: 'Département', value: 'departementId' },
            {
                text: 'Utilisateur WebCEE',
                value: 'compteWebCeeExistent',
                class: 'text-xs-center',
            },
            { text: 'Nom', value: 'nom' },
            { text: 'Prénom', value: 'prenom' },
            { text: 'E-mail', value: 'email' },
            { text: 'Téléphone', value: 'telephone' },
            { text: 'Objet', value: 'objetDemandeId' },
            {
                text: 'Numéro de dossier',
                value: 'numeroDossier',
            },
            { text: '', value: 'data-table-expand' },
        ];
        this.demandesUtilisateurs = [];
    }

    /**
     * Récupération de la liste des demandes utilisateur.
     */
    public getData() {
        this.loading = true;
        this.criteria.pagingAndSorting = PagingAndSorting.buildFromPaginationObject(
            this.pagination
        );
        const fetchAllDemandesUtilisateursApi = new ApiService<DemandeUtilisateur>(
            'demandeUtilisateur/obtenirTous'
        );
        fetchAllDemandesUtilisateursApi
            .getWhere(this.criteria.queryString)
            .then((response) => {
                this.demandesUtilisateurs = response.data.data;
                this.totalDemandesUtilisateurs = response.data.totalCount;
                this.$set(this.pagination, 'totalItems', this.totalDemandesUtilisateurs);
            })
            .finally(() => (this.loading = false));
    }

    /**
     * Affichage du message de la demande utilisateur.
     *
     * @param {*} message
     * @memberof GestionDemandesUtilisateurs
     */
    afficherMessage(message: string) {
        this.dialog = true;
        this.message = message;
    }

    /**
     * Suppression de la demande utilisateur.
     *
     * @param {DemandeUtilisateur} item
     * @returns {Promise<DemandeUtilisateur>}
     * @memberof GestionDemandesUtilisateurs
     */
    async supprimerDemande(
        item: DemandeUtilisateur
    ): Promise<DemandeUtilisateur> {
        if (
            await this.$refs.confirm.open(
                'Suppression demande de contact',
                `Confirmez-vous la suppression de la demande de contact numéro ${
                item.id
                }?`,
                null
            )
        ) {
            const apiSuppression = new ApiService<DemandeUtilisateur>(
                'demandeUtilisateur'
            );
            return new Promise<DemandeUtilisateur>((resolve, reject) => {
                return apiSuppression
                    .delete(item.id)
                    .then((response) => {
                        resolve(response.data.data);
                        if (!response.data.isError) {
                            const index = this.demandesUtilisateurs.indexOf(item);
                            // Rechargement des demandes utilisateur quand la suppression est terminée.
                            this.demandesUtilisateurs.splice(index, 1);
                        }
                    })
                    .catch((error: { response: any }) => {
                        reject(error.response);
                    });
            });
        }
        return undefined;
    }

    /**
     * Exporter la liste des demandes utilisateurs.
     *
     * @memberof GestionDemandesUtilisateurs
     */
    public exportDonnees(): void {
        const apiExport = new ApiService<FileContentResult>(
            `/demandeUtilisateur/exportExcel`
        );
        this.loading = true;
        apiExport
            .getWhereSingle(this.criteria.queryString)
            .then((reponse) => {
                ApiHelper.createAndDownloadBlobFile(reponse.data);
            })
            .finally(() => (this.loading = false));
    }
}
